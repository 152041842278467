import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import CompanyDetail from '../../components/company-detail/detail';
import Loading from '../../components/ui/loading';
import { getAPIRoot } from '../../helpers/fetch';
import Layout from '../../modules/layout';

const PreviewCompanyDetail = () => {
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPartial, setIsPartial] = useState(false);

  useEffect(() => {
    const url = new URL(window.location);
    const getCompanyDetail = async () => {
      let apiRoot = await getAPIRoot();

      setLoading(true);
      fetch(
        `${apiRoot}${
          process.env.GATSBY_API_COMPANY_DETAIL
        }/${url.searchParams.get('profileId')}`
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 404) {
            return navigate('/404');
          }
          setLoading(false);
          setCompanyData(res);
        })
        .catch((e) => {
          navigate('/404');
        });
    };

    getCompanyDetail();
  }, []);

  useEffect(() => {
    const url = new URL(window.location);
    if (url.searchParams.get('partial') === '1') {
      setIsPartial(true);
    }
  }, []);

  if (!companyData || loading) {
    return (
      <Layout
        title={'title.loadingPage'}
        description={'desc.loadingPage'}
        dynamicPagePreview
        preview
      >
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout
      title={companyData.companyName}
      description={companyData.introductionHeadline}
      dynamicPagePreview
      preview
    >
      <CompanyDetail data={companyData} isPartial={isPartial} />
    </Layout>
  );
};

export default PreviewCompanyDetail;
